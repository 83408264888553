import { type ReactNode } from 'react';

import { FormattedMessage } from 'dibs-react-intl';

import {
    tradeLinks,
    sellerLinks,
    companyLinks,
    supportLinks,
    sitemapLinks,
    connectLinks,
} from './Footer/links';
import { type FooterLink, type footerEnablerKeys } from './Footer/FooterLinkTypes';

export type PartialFooterLinkConfig = {
    title: ReactNode;
    items: FooterLink[];
    enablerKey?: footerEnablerKeys;
    disablerKey?: footerEnablerKeys;
};

export type FooterLinkConfig = PartialFooterLinkConfig & {
    additionalLink?: PartialFooterLinkConfig;
    sectionName?: string;
    columnWidth: number;
};

export type MobileFooterLinkConfig = PartialFooterLinkConfig & {
    isExpanded?: boolean;
    isAlwaysExpanded?: boolean;
};

const tradeLinksConfig: PartialFooterLinkConfig = {
    title: <FormattedMessage id="footer.tradeLinks.title" defaultMessage="Trade" />,
    items: tradeLinks,
};

const sellerLinksConfig: PartialFooterLinkConfig = {
    title: <FormattedMessage id="footer.sellerLinks.title" defaultMessage="Sellers" />,
    items: sellerLinks,
};

const companyLinksConfig: PartialFooterLinkConfig = {
    title: <FormattedMessage id="footer.ourCompany.title" defaultMessage="Our Company" />,
    items: companyLinks,
};

const supportLinksConfig: PartialFooterLinkConfig = {
    title: <FormattedMessage id="footer.customerSupport.title" defaultMessage="Customer Support" />,
    items: supportLinks,
};

const sitemapLinksConfig: PartialFooterLinkConfig = {
    title: <FormattedMessage id="footer.categories.title" defaultMessage="Categories" />,
    items: sitemapLinks,
};

const connectLinksMessage = (
    <FormattedMessage id="footer.connect.title" defaultMessage="Connect With Us" />
);

const connectLinksConfig: PartialFooterLinkConfig = {
    title: connectLinksMessage,
    items: connectLinks,
};

export const linkColumnConfig: FooterLinkConfig[] = [
    {
        ...sitemapLinksConfig,
        columnWidth: 2,
    },
    {
        ...tradeLinksConfig,
        additionalLink: sellerLinksConfig,
        columnWidth: 2,
    },
    {
        ...companyLinksConfig,
        columnWidth: 2,
    },
    {
        ...supportLinksConfig,
        columnWidth: 2,
    },
    {
        ...connectLinksConfig,
        sectionName: 'connect-with-us',
        columnWidth: 3,
    },
];

export const mobileLinkDrawerConfig: MobileFooterLinkConfig[] = [
    sitemapLinksConfig,
    tradeLinksConfig,
    sellerLinksConfig,
    companyLinksConfig,
    { ...supportLinksConfig, isExpanded: true },
    { ...connectLinksConfig, isAlwaysExpanded: true },
];

export const filterLinks = (links: FooterLink[], viewer: $TSFixMe): FooterLink[] => {
    return links.filter(link => {
        if (
            (link.enablerKey && viewer[link.enablerKey] === false) ||
            (link.disablerKey && viewer[link.disablerKey] === true)
        ) {
            return null;
        }

        return link;
    });
};
